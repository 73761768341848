import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import Privacy from "./privacy";

const routing = (
  <Router>
    <Route path="/" exact component={App} />
    <Route path="/terms-privacy" component={Privacy} />
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));
