import React from "react";
import {
  Provider,
  Heading,
  Flex,
  NavLink,
  Small,
  Text,
  Box,
  Image
} from "rebass";
import logo from "./images/paid-logo.png";

const Privacy = props => (
  <Provider>
    <Flex flexWrap="wrap" alignItems="center">
      <Heading alignItems="center" mt={5} mb={10} ml={20}>
        <a href="/">
          <Image src={logo} width={150} mb={2} />
        </a>
      </Heading>
      <Box ml={20} mr={20}>
        <Heading>Terms</Heading>
        <Text fontSize={2} color="primary">
          Instavocab (App, Service) is provided on an “as is” basis. To the
          fullest extent permitted by applicable law, Instavocab makes no
          representations or warranties, express or implied, regarding the use
          or the results of this service of its correctness, accuracy, adequacy,
          usefulness, timeliness, reliability or otherwise. Instavocab shall
          have no liability for providing this service. The app provides no
          guarantee to the functions and actions performed. It is the
          responsibility of the users to properly use the tools provided by the
          app.
        </Text>
      </Box>
      <Box mt={4} ml={20} mr={20}>
        <Heading>Privacy Policy</Heading>
        <Text fontSize={2} color="primary">
          As a condition to using the service, you agree to the terms of the
          Instavocab Privacy Policy. Instavocab understands that privacy is
          important to you and we don’t use any information outside of the app.
        </Text>
      </Box>
    </Flex>

    <Flex is="footer" alignItems="center" p={3}>
      <NavLink children="Instavocab" href="/" />

      <NavLink children="Help" href="mailto:helloinstavocab@gmail.com" />
      <NavLink children="Terms & Privacy" href="/terms-privacy" />
      <Small color="grey" ml="auto">
        © Instavocab
      </Small>
    </Flex>
  </Provider>
);

export default Privacy;
