import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

import {
  Image,
  Provider,
  Heading,
  Subhead,
  Flex,
  NavLink,
  Small
} from "rebass";
import { Hero, CallToAction, Phone } from "react-landing-page";
import screen1 from "./images/screen1.png";
import screen2 from "./images/screen2.png";
import logo from "./images/paid-logo.png";

ReactGA.initialize("UA-258145-14");
const browserHistory = createBrowserHistory();
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

const App = props => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Provider>
      <Hero color="black" bg="white" backgroundImage="">
        <Flex flexWrap="wrap" alignItems="center">
          <Flex alignItems="flex-start" width={[1, 1 / 2]} p={3}>
            <Phone
              src={screen2}
              notch
              style={{ transform: "translate(32px, 64px)" }}
            />
            <Phone
              src={screen1}
              color="white"
              style={{ transform: "translate(-32px, 0px)" }}
            />
          </Flex>
          <Flex
            width={[1, 1 / 2]}
            alignItems="center"
            flexDirection="column"
            p={3}
          >
            <Heading alignItems="center" mt={5}>
              <Image src={logo} width={150} mb={2} />
            </Heading>
            <Subhead fontSize={[2, 3]} color={"#4e4f4f"} alignItems="center">
              Learn. Like. Swipe.
            </Subhead>
            <Flex mt={3} flexWrap="wrap" justifyContent="center">
              <CallToAction
                bg="#fffc00"
                href="https://apps.apple.com/us/app/instavocab/id1480190766?ls=1"
                target="_blank"
                mt={1}
                mr={1}
                color="#000"
              >
                 App Store
              </CallToAction>
              <CallToAction
                bg="#fffc00"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.instavocab"
                mt={1}
                mr={1}
                color="#000"
              >
                Google Play
              </CallToAction>
            </Flex>
          </Flex>
        </Flex>
      </Hero>

      <Flex is="footer" alignItems="center" p={3}>
        <NavLink children="Instavocab" href="/" />
        <NavLink children="Help" href="mailto:helloinstavocab@gmail.com" />
        <NavLink
          children="Terms & Privacy"
          target="_blank"
          href="/terms-privacy"
        />
        <Small color="grey" ml="auto">
          © Instavocab
        </Small>
      </Flex>
    </Provider>
  );
};

export default App;
